var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "govuk-footer", attrs: { role: "contentinfo" } },
    [
      _c(
        "div",
        { staticClass: "govuk-width-container", class: _vm.containerClasses },
        [
          _vm.navigation
            ? [
                _c(
                  "div",
                  { staticClass: "govuk-footer__navigation" },
                  _vm._l(_vm.navigation, function(item, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "govuk-footer__section" },
                      [
                        _c(
                          "h2",
                          {
                            staticClass: "govuk-footer__heading govuk-heading-m"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.title) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "ul",
                          {
                            staticClass: "govuk-footer__list",
                            class: _vm.listClasses(item)
                          },
                          _vm._l(item.items, function(item, key) {
                            return item.href && item.text
                              ? _c(
                                  "li",
                                  {
                                    key: key,
                                    staticClass: "govuk-footer__list-item"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "govuk-footer__link",
                                        attrs: { href: item.href }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.text) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          }),
                          0
                        )
                      ]
                    )
                  }),
                  0
                ),
                _c("hr", { staticClass: "govuk-footer__section-break" })
              ]
            : _vm._e(),
          _c("div", { staticClass: "govuk-footer__meta" }, [
            _c(
              "div",
              {
                staticClass:
                  "govuk-footer__meta-item govuk-footer__meta-item--grow"
              },
              [
                _vm.meta && _vm.meta.items
                  ? [
                      _c("h2", { staticClass: "govuk-visually-hidden" }, [
                        _vm._v("Support links")
                      ]),
                      _c(
                        "ul",
                        { staticClass: "govuk-footer__inline-list" },
                        _vm._l(_vm.meta.items, function(item, key) {
                          return _c(
                            "li",
                            {
                              key: key,
                              staticClass: "govuk-footer__inline-list-item"
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "govuk-footer__link",
                                  attrs: { href: item.href }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.text) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  : _vm._e(),
                _c(
                  "span",
                  { staticClass: "govuk-footer__licence-description" },
                  [
                    _vm._v(
                      "\n          For any feedback or technical queries, please email\n          "
                    ),
                    _c(
                      "gov-link",
                      { attrs: { href: "mailto:" + _vm.contactEmail } },
                      [_vm._v(_vm._s(_vm.contactEmail))]
                    )
                  ],
                  1
                )
              ],
              2
            ),
            _vm._m(0)
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "govuk-footer__meta-item" }, [
      _vm._v("\n        Powered by\n        "),
      _c(
        "a",
        {
          staticClass: "govuk-footer__link",
          attrs: { href: "https://ayup.agency/" }
        },
        [_vm._v("Ayup Connect")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }